@use 'styles/base/mixins';
@use 'styles/base/variables';

.contacts {
  margin-bottom: 4rem;

  @include mixins.screen-md {
    margin-bottom: 5rem;
  }

  & .contactsWrapper {
    --gap: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    &.threeTilesPerRow {
      & .contact {
        @include mixins.screen-md {
          flex-basis: calc(calc(100% - var(--gap) - var(--gap) - var(--gap)) / 3);
        }
      }
    }
  }

  & .contact {
    margin: var(--gap) 0 0 var(--gap);
    flex-basis: 100%;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: variables.$gb_gdds_cl03;
    text-align: center;

    @include mixins.screen-sm {
      flex-basis: calc(calc(100% - var(--gap) - var(--gap)) / 2);
    }

    position: relative;

    & .image {
      margin-bottom: 0.5rem;
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    a:before {
      content: none;
    }

    & .title {
      margin: 0 0 1rem 0;
    }

    .email {
      margin: 0;
    }
  }

  &#{&}#{&}#{&} .contact {
    & .contactName {
      margin-bottom: 0.125rem;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
}

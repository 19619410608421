.voucherControl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: bold;
    margin:0;
  }

  button {
    height: 100%;
    padding: 0 1rem;

    i {
      display: inline-block;
      font-size: 1.5rem;
      line-height:0;
    }
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

a:hover {
  cursor: pointer;
}

.contact {
  height: 100%;

  h6 + div {
    margin: 0.75rem 0 0.5rem 0;
  }

  & > div {
    height: 100%;
  }

  div {
    border: none;
  }
}

.productiveTile {
  height: 100%;

  p {
    margin: 0.75rem 0 0.5rem;
    color: variables.$gb_gdds_cl14;
  }

  &:not(.tileBorder) div {
    border: none;
  }

  > div {
    height: 100%;
  }
}

.expressiveWrapper {
  display: flex;
  flex-direction: column;
  margin: variables.$spacing-gdds-section-mobile-small;

  @include mixins.screen-md {
    margin: variables.$spacing-gdds-section-small;
  }

  &.grey {
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-small;

    @include mixins.screen-md {
      padding: variables.$spacing-gdds-section-small;
    }
  }

  a::before {
    content: none;
  }

  .grid {
    width: 100%;
  }
}

.productiveWrapper {
  margin: 1.25rem 0;

  &#{&} .headline {
    h2 {
      margin-bottom: 1.5rem;
    }
  }

  @include mixins.screen-md {
    margin: variables.$spacing-gdds-section-small;
  }

  :global(.nord) &,
  :global(.xy) &,
  :global(.kolo) &,
  :global(.twyford) & {
    margin: 2.5rem 0 4rem;

    p,
    .copyText {
      font-size: 1rem;
      color: variables.$gb_black;
    }

    h2 {
      margin-bottom: 0;
    }

    h2 + p {
      margin-top: 1rem;
    }

    .tilesWrapper {
      margin-top: 2rem;

      @include mixins.screen-md {
        margin-top: 2.5rem;
      }

      .productiveTile.noImageBorder div[data-testid='tleab1-input'] :global(.image-container) {
        border: 1px solid variables.$gb_gdds_cl09;
        border-bottom: none;
      }
    }

    @include mixins.screen-md {
      margin: 4rem 0 5rem;
    }

    div[role='img'] {
      background-size: cover;
    }

    h4 {
      &:global(.gdds-element) {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }

    a {
      span {
        color: var(--gb-brand-primary-light);
      }

      &:global(.gdds-element) > span > svg {
        fill: var(--gb-brand-primary-light);
      }

      &:global(.gdds-element):hover span:last-of-type {
        border-bottom: 1px solid var(--gb-brand-primary);
        color: var(--gb-brand-primary);
      }

      &:global(.gdds-element):hover span > svg {
        fill: var(--gb-brand-primary);
      }

      &:global(.gdds-element):active span:last-of-type {
        border-color: transparent;
        color: variables.$gb_white;
      }

      &:global(.gdds-element):active span > svg {
        fill: variables.$gb_white;
      }
    }
  }

  &.border {
    :global(.nord) &,
    :global(.kolo) &,
    :global(.twyford) & {
      margin: variables.$spacing-nordics-mobile;

      @include mixins.screen-md {
        margin: variables.$spacing-nordics-desktop;
      }
    }
  }

  &.grey {
    margin: 0;
    padding: 1.25rem 0;

    @include mixins.screen-md {
      padding: variables.$spacing-gdds-section-small;
    }
  }

  .teaser {
    margin: -0.75rem auto;
  }

  h2 {
    margin-bottom: 1.25rem;

    @include mixins.screen-md {
      margin-bottom: 1.5rem;
    }
  }

  :global(.twyford) & {
    h2 {
      font-weight: variables.$font-weight-bold;

      span {
        font-weight: variables.$font-weight-bold;
      }

      span[class*='headline_subtitle'] {
        font-weight: variables.$font-weight-light;

        span {
          font-weight: variables.$font-weight-light;
        }
      }
    }
  }

  li,
  .tile {
    padding-bottom: 0;
  }

  .buttonRow button {
    margin: 1.5rem auto 0;
  }

  &.isLast {
    margin-bottom: 0;
  }
}

.tile {
  margin: 0.75rem 0;
}

.white {
  background-color: variables.$gb_white;
}

.grey {
  background-color: variables.$gb_grey_050;
}

.carouselWrapper {
  > div {
    padding: 0;
  }

  :global(.carousel-container) {
    gap: 1rem;
  }
}

.jobtile {
  overflow: hidden;
}

.vacancies {
  margin-bottom: 1.125rem;

  @include mixins.screen-md {
    margin-bottom: 1.25rem;
  }
}

.countries {
  margin-bottom: 1.875rem;

  @include mixins.screen-md {
    margin-bottom: 0.5rem;
  }
}

.vacancies,
.countries {
  display: flex;
  flex-direction: row;

  span {
    align-self: center;
  }

  .vacanciesContent,
  .countriesContent {
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;

    p {
      font-size: 0.875rem;
      margin-bottom: 0;
    }

    span {
      align-self: unset;
    }
  }
}

.vacanciesTypo,
.countriesTypo {
  font-size: 2.625rem;
  line-height: 2rem;
  font-weight: bold;
}

.vacanciesTypo {
  @include mixins.screen-lg {
    line-height: 1;
    font-size: 3.625rem;
  }
}

.countriesTypo {
  @include mixins.screen-lg {
    line-height: 3rem;
    font-size: 2.875rem;
  }
}

.heroTile__carousel {
  div[data-testid='image-with-headline-input'] {
    height: auto;

    > div:nth-child(2) {
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  :global(.carousel-container) {
    position: relative;
    overflow: initial;
  }

  :global(.gdds-carousel__left-button) {
    position: absolute;
    left: -3.5rem;
    height: 100%;
  }

  :global(.gdds-carousel__right-button) {
    position: absolute;
    right: -3.5rem;
    height: 100%;
  }
}

.herotile {
  &#{&}#{&}#{&} h2 {
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
  }

  &#{&}#{&}#{&} h2 span {
    display: inline;

    &:before {
      content: '';
      display: block;
    }
  }

  &#{&}#{&}#{&} h2,
  &#{&}#{&}#{&} h2 span {
    font-weight: 400;
    text-transform: uppercase;
    @include mixins.typography-h-2();

    :global(.kolo) & {
      font-weight: variables.$font-weight-bold;
    }

    :global(.twyford) & {
      text-transform: capitalize;
      font-weight: variables.$font-weight-light;

      span {
        font-weight: variables.$font-weight-medium;
      }
    }
  }

  @include mixins.screen-sm {
    :global(.image-container) > div {
      background-position: center right;
    }
  }

  @include mixins.screen-lg {
    :global(.image-container) > div {
      background-position: center center;
    }
  }
}

.bg-porcelain {
  background-color: variables.$porcelain;
}

.bg-wave {
  background-color: variables.$wave;
}

.bg-sky {
  background-color: variables.$sky;
}

.bg-norway {
  background-color: variables.$norway;
}

.bg-stone {
  background-color: variables.$stone;
}

:global(.kolo),
:global(.twyford) {
  /*
  "Porcelain" to "Porcelain/Grey"
  "Wave" to "Nordics - Wave"
  "Sky" to "Nordics - Sky"
  "Norway" to "Nordics - Norway"
  "Stone" to "Nordics - Stone"

  In Kolo: All "Nordics - …" Colors will be white
  In Twyford: All "Nordics - …" Colors will be white
  */
  .grey {
    background-color: var(--white);
  }

  .bg-wave {
    background-color: var(--white);
  }

  .bg-sky {
    background-color: var(--white);
  }

  .bg-norway {
    background-color: var(--white);
  }

  .bg-stone {
    background-color: var(--white);
  }
}

.centeredHeadline h2 {
  @include mixins.screen-md {
    text-align: center;
  }
}

.bg-stone,
.bg-norway,
.bg-sky,
.bg-wave,
.bg-porcelain {
  padding: 2.5rem 0 4rem;
  margin: 0;

  @include mixins.screen-md {
    padding: 4rem 0 5rem;
  }

  &.border {
    padding: 2.5rem 0 0;

    @include mixins.screen-md {
      padding: 4rem 0 0;
    }
  }
}

.pFull {
  max-width: 100%;
}

.tileWrapper {
  position: relative;

  a {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .autofilled.autofilled.autofilled {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: variables.$gb_cc_actions;
    z-index: 1;
    min-width: auto;
    transform: none;

    &::before {
      font-size: inherit;
      color: variables.$gb_white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '\f10e';
      line-height: 1;
      font-family: geberitPlatfomWeb20IconFont, sans-serif;
      padding: 0;
    }
  }
}

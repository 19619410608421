@use 'styles/base/mixins';
@use 'styles/base/variables';

.loadmoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  @include mixins.screen-sm {
    margin-top: 1.75rem;
  }

  @include mixins.screen-md {
    margin-top: 2rem;
  }

  > button {
    min-width: 16rem;
  }
}

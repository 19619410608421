@use 'styles/base/mixins';
@use 'styles/base/variables';

.socialShare {
  margin: 0.625rem 0 1.875rem 0;
  display: flex;
  flex-direction: column;

  @include mixins.screen-sm {
    margin-top: 0;
  }

  @include mixins.screen-md {
    margin: 2rem 0;
    padding-left: 1.5rem;
    height: 2.375rem;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }

  .socialShareHeadline {
    font-weight: bold;

    @include mixins.screen-md {
      margin: 2rem 2.625rem 2rem 0;
    }
  }

  .socialShareIcons {
    display: flex;
    align-items: center;

    a.link {
      min-width: unset;
      cursor: pointer;

      &.copyIcon {
        stroke: black;
      }

      .Envelope {
        svg path {
          fill: grey;
        }
      }

      .Facebook,
      .Linkedin,
      .Twitter,
      .copyIcon {
        svg path {
          stroke: black;
        }
      }
    }
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

.tile {
  margin-bottom: 0;
  height: 100%;
  padding: 0;

  @include mixins.screen-sm {
    padding: 0 0.5rem;
  }

  :global(.image) {
    margin-bottom: 1rem;

    &::after {
      background: variables.$gb_gdds_cl15_50;
      border-color: variables.$gb_white;
      color: variables.$gb_white;
    }

    :global(.description) {
      p {
        @include mixins.line-clamp(3);
      }

      button {
        position: initial;
        margin-top: 1.5rem;
      }
    }
  }
}

:global(.carousel-container) {
  :global(.xy) & {
    h3 {
      margin: 0;
    }
  }
}

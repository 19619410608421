@use 'styles/base/mixins';
@use '../../checkout.module.scss';
@use 'styles/base/variables';

.h1 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(31, 35, 42);
  margin-bottom: variables.$spacing-m;

  @include mixins.below-screen-sm {
    margin-top: variables.$spacing-m;
    line-height: 2.5rem;
  }

  > span {
    display: block;
    font-size: inherit;
    font-weight: variables.$font-weight-bold;
  }
}

.h3 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(18, 20, 24);
  font-weight: variables.$font-weight-bold;
}

.customerNumberText {
  display: block;
  font-size: inherit;
  font-weight: variables.$font-weight-bold;
  text-transform: uppercase;
}

.bottomMargin {
  margin-bottom: variables.$spacing-m;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  border: none !important;

  @include mixins.below-screen-sm {
    display: none;
  }
}

.editButton {
  margin-bottom: 2rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: #000;
  }
}

.cartOverviewDevider {
  color: black;
  width: 100%;
}

.loaderWrapper {
  display: block;
  width: 100%;
  margin: 30% 0;

  > div {
    margin: auto;
  }
}

.singlePaymentLabel {
  margin-left: variables.$spacing-xs;
}

.termsAndConditions {
  @include mixins.below-screen-sm {
    padding: 1rem;
  }

  .radioInputWrapper {
    margin-right: variables.$spacing-s;
  }

  .bottomMargin {
    margin-bottom: variables.$spacing-xs;
  }

  .label {
    position: relative;
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

.tabsWrapper {
  background-color: variables.$gb_white;

  ul {
    margin-bottom: 0;

    li {
      max-height: 2.75rem;

      > div {
        max-height: 2.75rem;
      }
    }

    @include mixins.screen-md {
      li {
        max-height: 2.5rem;

        > div {
          max-height: 2.5rem;
        }
      }
    }
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

.headline {
  max-width: 51.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;

  @include mixins.screen-sm {
    max-width: 52.5rem;
    padding: 0 1.5rem;
  }
}

.breadcrumbs {
  margin: 1.875rem auto;
}

.heroImage {
  @include mixins.screen-md {
    margin-bottom: 2rem;
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

.introText {
  margin-bottom: 3.125rem;
  @include mixins.bullet-points-spacing();

  @include mixins.screen-md {
    margin-bottom: 4.375rem;
  }
}

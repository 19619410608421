@use 'styles/base/mixins';
@use 'styles/base/variables';

.fullgridImage {
  margin-bottom: 1.25rem;

  @include mixins.screen-md {
    margin-bottom: 1.875rem;
  }

  &.greyBackground {
    background-color: variables.$gb_grey_050;
  }

  &.whiteBackground {
    background-color: variables.$gb_white;
  }

  &.isContainerChild {
    margin: variables.$spacing-gdds-section-mobile;

    @include mixins.screen-md {
      margin: variables.$spacing-gdds-section;
    }

    &.greyBackground {
      background-color: variables.$gb_grey_050;
      margin: 0;
      padding: variables.$spacing-gdds-section-mobile;

      @include mixins.screen-md {
        padding: variables.$spacing-gdds-section;
      }
    }

    &.whiteBackground {
      background-color: variables.$gb_white;
    }
  }

  .imageWrapper {
    line-height: 0;
  }

  img {
    width: 100%;
  }
}

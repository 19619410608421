@use 'styles/base/functions';
@use 'styles/base/mixins';
@use 'styles/base/variables';

.headline {
  &#{&}#{&} {
    display: block;
    z-index: 1;
    text-transform: uppercase;

    b {
      display: block;
    }

    span {
      display: block;
    }

    &.h1,
    &.h1 span {
      @include mixins.typography-h-1();
    }

    &.h2,
    &.h2 span {
      @include mixins.typography-h-2();
    }
  }
}

.heroImageThemedH1 {
  z-index: 1;
  letter-spacing: functions.calc-letter-spacing(5);
  font-size: variables.$gdds-font-size-h1-s;
  line-height: variables.$gdds-line-height-h1-s;

  span {
    text-transform: variables.$text-transform-up;
  }

  :global(.nord) & {
    span + span {
      font-family: variables.$gb_nordicsFontQuickbrush;
      text-transform: inherit;
    }
  }

  :global(.kolo) & {
    span {
      font-weight: variables.$font-weight-bold !important; // necessary
    }
    span + span {
      text-transform: uppercase;
    }
  }

  :global(.twyford) & {
    span {
      text-transform: capitalize !important; // necessary
    }
    span + span {
      font-weight: variables.$font-weight-medium;
    }
  }

  @include mixins.screen-sm {
    font-size: variables.$gdds-font-size-h1-m;
    line-height: variables.$gdds-line-height-h1-m;
  }

  @include mixins.screen-md {
    font-size: variables.$gdds-font-size-h1-l;
    line-height: variables.$gdds-line-height-h1-l;
  }

  @include mixins.screen-lg {
    font-size: variables.$gdds-font-size-h1-xl;
    line-height: variables.$gdds-line-height-h1-xl;
  }

  span {
    display: block;
  }
}

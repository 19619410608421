@use 'styles/base/mixins';
@use '../../checkout.module.scss';
@use 'styles/base/variables';

.h1 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(31, 35, 42);
  margin-bottom: variables.$spacing-m;

  @include mixins.below-screen-sm {
    margin-top: variables.$spacing-m;
    line-height: 2.5rem;
  }

  > span {
    display: inline-block;
    font-size: inherit;
    font-weight: variables.$font-weight-bold;
  }
}

.h3 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(18, 20, 24);
  font-weight: variables.$font-weight-bold;
}

.customerNumberText {
  display: block;
  font-size: inherit;
  font-weight: variables.$font-weight-bold;
  text-transform: uppercase;
}

.topMargin {
  margin-top: variables.$spacing-m !important;
}

.bottomMargin {
  margin-bottom: variables.$spacing-m !important;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  border: none !important;

  @include mixins.below-screen-sm {
    display: none;
  }
}

.cartFooter {
  background: variables.$gb_grey_050;
  padding: variables.$spacing-m;

  @include mixins.below-screen-sm {
    padding: 1rem;
  }

  &Text {
    margin-bottom: 1rem;
  }
}

.textAlignRight {
  text-align: right;
}

.cartOverviewDevider {
  color: black;
  width: 100%;
}

.loaderWrapper {
  display: block;
  width: 100%;
  margin: 30% 0;

  > div {
    margin: auto;
  }
}

.footerButtonRow {
  display: flex;
  justify-content: flex-end;

  > button {
    width: unset !important;
  }
}

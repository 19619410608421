@use 'styles/base/mixins';
@use 'styles/base/variables';

.mediaTilessWrapper {
  --gap: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & .mediaTile {
    margin: var(--gap) 0 0 var(--gap);
    overflow: hidden;
    flex-basis: 100%;
    text-align: left;
    position: relative;

    img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }

    @include mixins.screen-sm {
      flex-basis: calc(calc(100% - var(--gap) - var(--gap)) / 2);
    }

    @include mixins.screen-md {
      flex-basis: calc(calc(100% - var(--gap) - var(--gap) - var(--gap)) / 3);

      &.relatedMaterial {
        flex-basis: calc(calc(100% - var(--gap) - var(--gap)) / 2);
      }
    }

    & .pressImage {
      width: 100%;
      height: 100%;
      background-size: cover;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & .transparentLayer {
            opacity: 0.9;
            cursor: pointer;
          }
        }
      }

      & .transparentLayer {
        padding: 1.5rem;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--gb-brand-primary-dark);
        opacity: 0;
        color: #ffffff;
        z-index: 1;
        transition: opacity 0.25s ease;

        & .mediaHeader {
          margin: 0;
          font-size: 1.125rem;
          line-height: 1.5rem;
        }

        > p {
          margin: 0.75rem 0 0 0;
          > span {
            display: block;
          }
        }

        > button {
          margin-top: 1rem;
        }
      }
    }
  }

  &.threeTilesPerRow {
    & .mediaTile {
      @include mixins.screen-md {
        flex-basis: calc(calc(100% - var(--gap) - var(--gap) - var(--gap)) / 3);
      }
    }
  }
}

.relatedMaterial {
  margin-bottom: 4rem;

  @include mixins.screen-md {
    margin-bottom: 5rem;
  }
}

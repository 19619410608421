@use 'styles/base/mixins';
@use 'styles/base/variables';

.shareIcons {
  display: flex;
  align-items: center;

  .socialPlatform {
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: none;
    }

    @include mixins.screen-md {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  svg,
  path {
    fill: variables.$gb_grey_800;
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

.materialsWrapper {
  :global(.headline-container) {
    background-color: var(--gb-brand-primary);
    > div {
      padding: 0;
      > p[font-size='p2'] {
        -webkit-line-clamp: 5;
      }

      & .copyText {
        font-size: 20px;
        line-height: 28px;

        @include mixins.screen-sm {
          font-size: 22px;
          line-height: 28px;
        }

        @include mixins.screen-md {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  &#{&}#{&} a {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  & .publishedAt {
    display: block;
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    @include mixins.screen-sm {
      margin-top: 3.75rem;
    }

    @include mixins.screen-md {
      margin-top: 2.5rem;
    }
  }

  & .publishedAt.tleb1 {
    color: variables.$gb_gdds_cl13;
    margin-top: 0;
  }

  & .copyText {
    margin: 0;
    font-size: 1.25;
    line-height: 1.75rem;
    font-weight: 400;
    text-transform: initial;

    @include mixins.screen-sm {
      font-size: 1.375rem;
      line-height: 1.75rem;
    }

    @include mixins.screen-md {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  & .copyText.tleb1 {
    margin: 0;
    color: variables.$gb-black;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;

    + a {
      padding-top: 0;
      padding-bottom: 0;
      &:before {
        top: 0;
      }
    }
  }
}

.relatedNews {
  margin-bottom: 4rem;

  &.twoTilesPerRow {
    &#{&} div[type='expressive'] {
      > div {
        @include mixins.screen-md {
          flex: 0 calc(50% - 1.5rem);
        }
      }
    }
  }

  @include mixins.screen-md {
    margin-bottom: 5rem;
  }
}

@use 'styles/base/mixins';
@use '../../checkout.module.scss';
@use 'styles/base/variables';

.cartFooter {
  background: variables.$gb_grey_050;
  padding: variables.$spacing-m;

  @include mixins.below-screen-sm {
    padding: 1rem;
  }

  &Text {
    margin-bottom: 1rem;
  }
}

.textAlignRight {
  text-align: right;
}

.voucherCartLine {
  color: variables.$gb_gdds_cl18;
}

.bottomMargin {
  margin-bottom: variables.$spacing-m !important;
}

@use '../../checkout.module.scss';
@use 'styles/base/variables';

.orderDetailsBoxWrapper {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    padding: 0;
    font-weight: variables.$font-weight-regular;
  }
}

.bottomMargin {
  margin-bottom: variables.$spacing-xs;
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

:global(.intro-text) {
  &#{&} {
    margin: 2rem 0 0 0;

    @include mixins.screen-sm {
      margin: 3rem 0 0 0;
    }
  }
}

.tilesHeadline {
  margin-top: 4rem;
  margin-bottom: 2rem;

  @include mixins.screen-md {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
  }
}

.pressPortal {
  width: 100%;

  & .headline h1 {
    padding: 0 0.5rem;
    margin-top: 2rem;
    margin-bottom: 0;
  }

  & .feedSection {
    margin-top: 4rem;
    padding-bottom: 4rem;
    background-color: variables.$gb_gdds_cl03;
  }

  @include mixins.screen-md {
    & .feedSection {
      margin-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  ul li:last-of-type {
    padding-bottom: 0.5rem;
  }

  & .tilesWrapper {
    margin-top: 2.5rem;

    @include mixins.screen-md {
      margin-top: 5rem;
    }
  }
}
